import * as React from "react";
import "../styles/main.scss";
import { graphql, Link } from "gatsby"


export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <main>
      <Link to="../">ᐊ zurück</Link>
      <h1>{ post.frontmatter.title }</h1>
      <h4>vom { post.frontmatter.date } | { post.timeToRead } min</h4>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </main>
  )
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        tags
      }
      timeToRead
    }
  }
`